import React, { useEffect, useState, useRef, useMemo } from 'react'
import styled from 'styled-components'
import media from 'styles/media'

import { Container } from 'components/atoms/Container'
import { Flat } from 'types/domain'
import { Filters } from 'hooks/useFilters'
import { FlatSearch } from './FlatSearch/FlatSearch'
import { FindFlat } from './FindFlat'

const SearchWraper = styled(Container)<{ paddingTop: number }>`
  max-width: none;
  box-sizing: border-box;

  padding-top: ${({ paddingTop }) => paddingTop && `${paddingTop}px`};

  ${media.xl.max} {
    padding: 0px;
    padding-left: 16px;
    padding-right: 16px;
  }
  ${media.sm.max} {
    padding-left: 0px;
    padding-right: 0px;
  }
`

const ContentWraper = styled(Container)<{ paddingTop: number }>`
  max-width: none;
  background-color: #f3f3f3;

  padding-top: ${({ paddingTop }) => paddingTop && `${paddingTop + 64}px`};

  ${media.lg.max} {
    padding-top: 40px;
    margin-top: 110px;
  }
  ${media.sm.max} {
    padding: 25px 0px;
    margin-top: 72px;
  }
`

const Content = styled(Container)`
  max-width: 1136px;

  position: relative;
`

type SearchProps = {
  flats: Flat[]
  availableFilters: Filters
  currentFilters: Filters
  setCurrentFilters: (filters: Filters) => void
  resetFilters: () => void
  searchTag?: keyof JSX.IntrinsicElements
  investmentsTag?: keyof JSX.IntrinsicElements
  heading?: string
}

export const Search: React.FC<SearchProps> = ({
  flats,
  currentFilters,
  setCurrentFilters,
  availableFilters,
  resetFilters,
  searchTag,
  investmentsTag,
  heading,
}) => {
  const HeroLableRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)
  const memoizedHeight = useMemo(() => height, [height])

  useEffect(() => {
    const updateHeight = () => {
      if (HeroLableRef.current) {
        setHeight(HeroLableRef.current.clientHeight)
      }
    }

    updateHeight()

    window.addEventListener('resize', updateHeight)

    return () => {
      window.removeEventListener('resize', updateHeight)
    }
  }, [])

  useEffect(() => {
    if (window.history.state?.filters) {
      setCurrentFilters(window.history.state.filters)
    }
  }, [])

  return (
    <SearchWraper padding={[0, 32]} paddingTop={memoizedHeight / 2}>
      <ContentWraper padding={[64, 32]} paddingTop={memoizedHeight / 2}>
        <Content margin={[0, 'auto']}>
          <FindFlat
            HeroLableRef={HeroLableRef}
            availableFilters={availableFilters}
            currentFilters={currentFilters}
            onFiltersChange={(filters) => setCurrentFilters(filters)}
            resetFilters={resetFilters}
            headingTag={searchTag}
          />

          <FlatSearch
            heading={heading}
            headingTag={investmentsTag}
            flats={flats}
          />
        </Content>
      </ContentWraper>
    </SearchWraper>
  )
}
